import m1Image from "../assets/images/products/Clothes/men/DSC00789.webp";
import m2Image from "../assets/images/products/Clothes/men/DSC00851.webp";
import fSHImage from "../assets/images/products/Food/honey/seasonal-honey.jpg";
import BPHImage from "../assets/images/products/Food/honey/Berry .jpg";

export const productData = {

  subCategories: [
    { parentCategory: "001", subCategoryName: "Unstitched Suits", smallCategoryName: ["Cotton", "Cotton Blends", "Linen", "Lawn", "Khaddar", "Karandi", "Wash & Wear", "Boski"] },
    { parentCategory: "001", subCategoryName: "Ready-to-Wear", smallCategoryName: ["Shalwar Kameez", "Kurta Shalwar", "Pant and Shirts", "Jeans & T-Shirts"] },
    { parentCategory: "001", subCategoryName: "Footwear", smallCategoryName: ["Sneakers & Casual Shoes", "Boots", "Formal Shoes", "Sandals & Slippers", "Jogging Shoes"] },
    { parentCategory: "001", subCategoryName: "Accessories", smallCategoryName: ["Watches", "Sunglasses", "Jewelry & Gems", "Wallets", "Belts"] },
    { parentCategory: "001", subCategoryName: "Grooming & Essentials", smallCategoryName: ["Fragrances", "Skincare", "Hair & Beard Care"] },
    { parentCategory: "002", subCategoryName: "Unstitched Suits", smallCategoryName: ["Cotton", "Cotton Blends", "Linen", "Lawn", "Khaddar", "Karandi", "Wash & Wear", "Boski"] },
    { parentCategory: "002", subCategoryName: "Ready-to-Wear", smallCategoryName: ["Kurtas & Kurtis", "Shalwar Kameez", "Formal Wear", "Dupattas & Shawls"] },
    { parentCategory: "002", subCategoryName: "Footwear", smallCategoryName: ["Khussas & Traditional Sandals", "Heels", "Flats & Slippers", "Sandals & Slippers", "Sneakers & Casual Shoes"] },
    { parentCategory: "002", subCategoryName: "Accessories", smallCategoryName: ["Watches", "Sunglasses", "Jewelry & Gems", "Scarves & Stoles", "Handbags & Clutches"] },
    { parentCategory: "002", subCategoryName: "Grooming & Essentials", smallCategoryName: ["Skincare", "Haircare", "Makeup", "Fragrances", "Henna & Nail Care"] },
    { parentCategory: '003', subCategoryName: 'Clothing', smallCategoryName: ['Shalwar Kameez'] },
    { parentCategory: '003', subCategoryName: 'Footwear', smallCategoryName: ['Sneakers'] },
    { parentCategory: '004', subCategoryName: 'Honey', smallCategoryName: ['Organic Honey'] },
    { parentCategory: '004', subCategoryName: 'Dried Fruits & Nuts', smallCategoryName: ['Almonds'] },
    { parentCategory: '005', subCategoryName: 'Superfoods', smallCategoryName: ['Turmeric Capsules'] },
    { parentCategory: '005', subCategoryName: 'Vitamins', smallCategoryName: ['Multivitamins'] },
    { parentCategory: '006', subCategoryName: 'Accessories', smallCategoryName: ['Earphones'] },
    { parentCategory: '006', subCategoryName: 'Power Banks', smallCategoryName: ['Portable Chargers'] },
    { parentCategory: '007', subCategoryName: 'Bedding Sets', smallCategoryName: ['Comforters', 'Duvets', 'Bed Sheets', 'Pillows'] },
    { parentCategory: '007', subCategoryName: 'Mattresses', smallCategoryName: ['Foam Mattresses', 'Spring Mattresses'] },
    { parentCategory: '007', subCategoryName: 'Blankets & Throws', smallCategoryName: ['Woolen Blankets', 'Fleece Blankets'] },
  ],
  products: [
    {
      id: '001-01',
      productName: 'Cotton Unstitched Suit',
      description: 'High-quality unstitched cotton suit for men.',
      firstLevelCategory: '001',
      secondLevelCategory: 'Unstitched Suits',
      smallCategoryName: 'Cotton',
      price: 2500,
      oldPrice: 3000,
      isFeatured: false,
      productStock: 50,
      brand: 'Classic Men',
      discount: '10%',
      meters: 2.5,
      colors: ['White', 'Black', 'Blue'],
      ratings: 4.5,
      location: 'Pakistan',
      media: [m1Image, m2Image],
      published: true,
    },
    {
      id: '001-02',
      productName: 'Linen Unstitched Suit',
      description: 'Premium linen fabric for stylish wear.',
      firstLevelCategory: '001',
      secondLevelCategory: 'Unstitched Suits',
      smallCategoryName: 'Linen',
      price: 3000,
      oldPrice: 3500,
      isFeatured: false,
      productStock: 30,
      brand: 'Elite Men',
      discount: '15%',
      meters: 3,
      colors: ['Beige', 'Grey', 'White'],
      ratings: 4.7,
      location: 'Pakistan',
      media: [m1Image, m2Image],
      published: true,
    },
    {
      id: '002-01',
      productName: 'Women Kurti',
      description: 'Elegant kurti for casual wear.',
      firstLevelCategory: '002',
      secondLevelCategory: 'Ready-to-Wear',
      smallCategoryName: 'Kurtas & Kurtis',
      price: 2000,
      oldPrice: 2500,
      isFeatured: true,
      productStock: 50,
      brand: 'Elegant Women',
      discount: '15%',
      sizeOptions: ['Small', 'Medium', 'Large', 'X Large'],
      colors: ['Pink', 'Yellow', 'Green'],
      ratings: 4.6,
      location: 'Pakistan',
      media: ['https://via.placeholder.com/300?text=Women+Kurti'],
      published: true,
    },
    {
      id: '002-02',
      productName: 'Women Heels',
      description: 'Comfortable and stylish heels.',
      firstLevelCategory: '002',
      secondLevelCategory: 'Footwear',
      smallCategoryName: 'Heels',
      price: 4000,
      oldPrice: 4500,
      isFeatured: false,
      productStock: 30,
      brand: 'Style Hub',
      discount: '10%',
      sizeOptions: ['36', '37', '38', '39', '40'],
      colors: ['Black', 'Red', 'Nude'],
      ratings: 4.5,
      location: 'Pakistan',
      media: ['https://via.placeholder.com/300?text=Women+Heels'],
      published: true,
    },
    {
      id: '004-03',
      productName: 'Seasonal Honey',
      description: 'Pure seasonal honey, harvested fresh.',
      firstLevelCategory: '004',
      secondLevelCategory: 'Honey',
      smallCategoryName: 'Seasonal Honey',
      price: 1700,
      oldPrice: 1800,
      isFeatured: true,
      productStock: 100,
      brand: 'HoneyCo',
      discount: '5%',
      meters: null,
      colors: null,
      ratings: 4.9,
      location: 'Pakistan',
      media: [fSHImage],
      published: true,
    },
    {
      id: '004-04',
      productName: 'Berry Premium Honey',
      description: 'Premium berry-flavored honey.',
      firstLevelCategory: '004',
      secondLevelCategory: 'Honey',
      smallCategoryName: 'Berry Premium Honey',
      price: 3500,
      oldPrice: 4000,
      isFeatured: true,
      productStock: 80,
      brand: 'BerryHoney',
      discount: '12%',
      meters: null,
      colors: null,
      ratings: 5.0,
      location: 'Pakistan',
      media: [BPHImage],
      published: true,
    },
    {
      id: '005-01',
      productName: 'Turmeric Capsules',
      description: 'Boost your immunity with turmeric capsules.',
      firstLevelCategory: '005',
      secondLevelCategory: 'Superfoods',
      smallCategoryName: 'Turmeric Capsules',
      price: 1000,
      oldPrice: 1200,
      isFeatured: true,
      productStock: 50,
      brand: 'Herbal Care',
      discount: '20%',
      meters: null,
      colors: null,
      ratings: 4.9,
      location: 'Pakistan',
      media: ['https://via.placeholder.com/300?text=Turmeric+Capsules'],
      published: true,
    },
    {
      id: '006-01',
      productName: 'Wireless Earphones',
      description: 'High-quality wireless earphones with long battery life.',
      firstLevelCategory: '006',
      secondLevelCategory: 'Accessories',
      smallCategoryName: 'Earphones',
      price: 3000,
      oldPrice: 3500,
      isFeatured: true,
      productStock: 30,
      brand: 'SoundPro',
      discount: '15%',
      meters: null,
      colors: ['Black', 'White'],
      ratings: 4.7,
      location: 'Pakistan',
      media: ['https://via.placeholder.com/300?text=Wireless+Earphones'],
      published: true,
    },
    {
      id: '007-01',
      productName: 'Comforter Set',
      description: 'Soft and cozy comforter set for a comfortable sleep.',
      firstLevelCategory: '007',
      secondLevelCategory: 'Bedding Sets',
      smallCategoryName: 'Comforters',
      price: 4500,
      oldPrice: 5000,
      isFeatured: true,
      productStock: 20,
      brand: 'Home Comfort',
      discount: '10%',
      meters: null,
      colors: ['Grey', 'Blue', 'Pink'],
      ratings: 4.6,
      location: 'Pakistan',
      media: ['https://via.placeholder.com/300?text=Comforter+Set'],
      published: true,
    },
    {
      id: '007-02',
      productName: 'Memory Foam Mattress',
      description: 'Supportive memory foam mattress for restful sleep.',
      firstLevelCategory: '007',
      secondLevelCategory: 'Mattresses',
      smallCategoryName: 'Foam Mattresses',
      price: 10000,
      oldPrice: 12000,
      isFeatured: false,
      productStock: 10,
      brand: 'SleepWell',
      discount: '15%',
      meters: null,
      colors: ['White', 'Grey'],
      ratings: 4.8,
      location: 'Pakistan',
      media: ['https://via.placeholder.com/300?text=Memory+Foam+Mattress'],
      published: true,
    },
  ],
};
